import React from "react";
import "./AboutUsbody.css";
import AboutImg from "../../src/assets/images/AboutUs/zl.jpg";

const AboutUsbody = () => {
  return (
    <div className="aboutbody">
      <div className="aboutus">
        <div className="imageAboutus">
          <img alt="aboutImg" src={AboutImg} />
        </div>
        <div className="aboutText">
          <h1>About Us</h1>
          <p>
            Okpo Myanmar is an oversea employment agency established in 2023 to
            provide essential training required by local workers to be employed
            overseas. We are focusing on becoming a leading international
            workforce solution provider in Myanmar for shipbuilding and
            infrastructure industries. Our services include training and
            supplying a passionate and talented human workforce for
            shipyards,factory and construction companies in South East Asia
            countries that include but are not limited to, South Korea, Japan,
            and UAE, where the demand for professional workers is tremendous.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsbody;
