import React from "react";
import "./Homebody.css";

const Homebody = () => {
  return (
    <div className="homebody">
      <div className="value">
        <h1>Our Value</h1>
        <p>
          Two side benefits - providing quality employees to the employers, as
          well as the employees gain their work based knowledges and income
          which they can benefits from the industries they work.
        </p>
      </div>
      <div className="valueBox1">
        <div className="box1">
          <h3>Proactive</h3>
          {/* <div className="boxBottom" /> */}
        </div>
        <div className="box1">
          <h3>Respect</h3>
          {/* <div className="boxBottom" /> */}
        </div>
      </div>
      <div className="valueBox2">
        <div className="box1">
          <h3>Integrity</h3>
          {/* <div className="boxBottom" /> */}
        </div>
        <div className="box1">
          <h3>Responsibility</h3>
          {/* <div className="boxBottom" /> */}
        </div>
      </div>
      <div className="objective">
        <h1>Our Objective</h1>
        <p>
          To provide global overseas employment opportunities for qualified,
          skilled, trained, sincere and enthusiastics manpower. To make a
          trustworthy connection between employees and employers.
        </p>
        <br></br>
        <p>
          To assists for uplifting country's economic status by providing
          quality and qualified manpower to the world and helping the government
          to increase the volume of foreign currency revenue.
        </p>
        <br></br>
        <p>
          To help share, learn and explorer further advancement for existing
          manpower.
        </p>
      </div>
    </div>
  );
};

export default Homebody;
