import React from "react";
import "./OurBackgroundsBody.css";
import Image1 from "../../src/assets/images/OurBackground/welding.jpg";
import Image2 from "../../src/assets/images/OurBackground/welding2.jpg";
import Image3 from "../../src/assets/images/OurBackground/airconditioning.jpg";
import Image4 from "../../src/assets/images/OurBackground/greeting.jpg";
import Image5 from "../../src/assets/images/OurPeople/learning.jpg";
import Image6 from "../../src/assets/images/OurPeople/posting.jpg";
import Image7 from "../../src/assets/images/OurPeople/girl_group.jpg";
import Image8 from "../../src/assets/images/OurPeople/welding.jpg";
import Image9 from "../../src/assets/images/OurPeople/griding.jpg";
import Image10 from "../../src/assets/images/OurPeople/learning.jpg";
import Image11 from "../../src/assets/images/OurPeople/learning2.jpg";
import Image12 from "../../src/assets/images/OurPeople/girl_group2.jpg";

const OurBackgroundsBody = () => {
  return (
    <div className="ourbackground">
      <div className="ourbackgroundText">
        <h1>Our Background</h1>
        <p>
          Although Okpo Myanmar is a newly founded company, we possess over a
          decade of experience and expertise in the shipbuilding industry since
          we are under the same ownership as United Myanmar Industry Company
          Limited (UMI) and Ngwe Takhon Company Limited.
        </p>
        <br />
        <p>
          UMI is a 15 years old shipbuilding company with its own shipyard and
          is expanding its scope of operations by adding and constructing a 100m
          x 50m dock presently. It provides new-built and repair services for
          ships (modification, fabrication, painting, blasting, etc.) with
          professionally trained, skillful local workers. Meanwhile, Ngwe
          Takhon, a shipping and logistics business, transports goods and cargo
          from its jetty via inland and coastal waterways using the ships built
          by UMI.
        </p>
        <br />
        <p>
          And we, Okpo Myanmar, are the ones who supply skill development with
          hands-on training methods and impart crucial safety knowledge in the
          workplace to them successively.
        </p>
      </div>

      <h3>United Myanmar Industry Company Limited</h3>
      <div className="imageUnited">
        <div className="line1">
          <img alt="image1" src={Image1} />
          <img alt="image2" src={Image2} />
        </div>
        <div className="line2">
          <img alt="image3" src={Image3} />
          <img alt="image4" src={Image4} />
        </div>
      </div>
      <h3>Our People</h3>
      <div className="imageOur">
        <div className="line1">
          <img alt="image5" src={Image5} />
          <img alt="image6" src={Image6} />
        </div>
        <div className="line2">
          <img alt="image7" src={Image7} />
          <img alt="image8" src={Image8} />
        </div>
      </div>
      <div className="imageOur">
        <div className="line1">
          <img alt="image5" src={Image9} />
          <img alt="image6" src={Image10} />
        </div>
        <div className="line2">
          <img alt="image7" src={Image11} />
          <img alt="image8" src={Image12} />
        </div>
      </div>
    </div>
  );
};

export default OurBackgroundsBody;
