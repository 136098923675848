import React from "react";
import "../components/Hero.css";

function HeroHome(props) {
  return (
    <>
      <div className={props.cName}>
        <img alt="Heroimg" src={props.heroImg} />

        <div className={props.cText}>
          <p>{props.text}</p>
        </div>
      </div>
    </>
  );
}

export default HeroHome;
