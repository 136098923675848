import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";

function Ourservices() {
  return (
    <div>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={require("../../src/assets/images/OurServices/service2.jpg")}
        cText="hero-text"
        text="Our Services"
      />
      <OurServices />
      <Footer />
    </div>
  );
}

export default Ourservices;
