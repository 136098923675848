export const Menu = [
    {
        title: "Home",
        url: "/",
        cName: "navbar-links",
        icon: "fa-solid fa-house-user"
    },
    {
        title: "About Us",
        url: "/about-us",
        cName: "navbar-links",
        icon: "fa-solid fa-circle-info"
    },
    {
        title: "Our Backgrounds",
        url: "/our-backgrounds",
        cName: "navbar-links",
        icon: "fa-solid fa-monument"
    },
    {
        title: "Our Services",
        url: "/our-services",
        cName: "navbar-links",
        icon: "fa-solid fa-handshake"
    },
    {
        title: "Contact",
        url: "/contact",
        cName: "navbar-links",
        icon: "fa-solid fa-address-book"
    },
]