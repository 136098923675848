import React, { useState } from "react";
import "./ContactUs.css";

const sendEmail = async (config) => {
  if (window.Email && typeof window.Email.send === "function") {
    try {
      await window.Email.send(config);
    } catch (error) {
      console.error(error);
    }
  }
};

const ContactUs = () => {
  const [formState, setFormState] = useState({});

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const submitHandler = (event) => {
    const config = {
      // SecureToken: "6c539798-0f95-4165-a9b1-93e7bdd9f528",
      // To: "web-admin@okpomyanmar.com",
      SecureToken: "961cc1d5-6603-44a1-86d5-a519187fc0f4",
      To: "info@okpomyanmar.com",
      From: "client-sender@okpomyanmar.com",
      Subject: "This email from your client.",
      Address: formState.address,
      Body: `${formState.message} - Your Name: ${formState.name},   Company Name: ${formState.companyName},   Address: ${formState.address},   Ph: ${formState.number},   Email: ${formState.email}`,
    };
    sendEmail(config).then(() => alert("Email sent successfully"));
    event.preventDefault();
    document.getElementById("name-box").value = "";
    document.getElementById("company-box").value = "";
    document.getElementById("email-box").value = "";
    document.getElementById("number-box").value = "";
    document.getElementById("address-box").value = "";
    document.getElementById("text-box").value = "";
  };

  const iconColor = ["var(--primary)"];

  return (
    <>
      <div className="contactContainer">
        <div className="infoContact">
          <h2>Contact Us</h2>
          <h1>Get In Touch</h1>
          <div className="address">
            <i class="fas fa-map-location-dot" style={{ color: iconColor }}></i>
            <h3>Office Location</h3>
            <p>
              No.5, Nature Park Residences, Mindama Street, Mayangone Township,
              Yangon, Myanmar
            </p>
          </div>
          <div className="address">
            <div className="tel">
              <i class="fas fa-phone" style={{ color: iconColor }}></i>
              <h3>Telephone Number</h3>
              <p>+959-965 500 061</p>
            </div>
          </div>
          <div className="address">
            <i
              class="fas fa-envelope-open-text"
              style={{ color: iconColor }}
            ></i>
            <h3>Email Address</h3>
            <p>info@okpomyanmar.com</p>
          </div>
        </div>
        <div className="contact">
          <form className="contactForm" onSubmit={submitHandler}>
            <div>
              <div className="labelRow1">
                <label>
                  Your Name<span>*</span>
                </label>
                <label>
                  Company Name<span>*</span>
                </label>
              </div>

              <div className="inputRow1">
                <input
                  className="contactInput"
                  id="name-box"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formState.name || ""}
                  onChange={changeHandler}
                  required="reqiureed"
                />

                <input
                  className="contactInput"
                  id="company-box"
                  type="text"
                  name="companyName"
                  placeholder="Company"
                  value={formState.companyName || ""}
                  onChange={changeHandler}
                  required="reqiureed"
                />
              </div>
            </div>
            <div>
              <div className="labelRow1">
                <label>
                  Email<span>*</span>
                </label>
                <label>
                  Contact Number<span>*</span>
                </label>
              </div>
              <div className="inputRow2">
                <input
                  className="contactInput"
                  id="email-box"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formState.email || ""}
                  onChange={changeHandler}
                  required="required"
                />

                <input
                  className="contactInput"
                  id="number-box"
                  type="text"
                  name="number"
                  placeholder="Phone Number"
                  value={formState.number || ""}
                  onChange={changeHandler}
                  required="reqiureed"
                />
              </div>
            </div>
            <div className="labelRow1">
              <label>
                Address<span>*</span>
              </label>
            </div>
            <input
              className="contactInput"
              id="address-box"
              type="text"
              name="address"
              placeholder="Address"
              value={formState.address || ""}
              onChange={changeHandler}
              required="reqiureed"
            />
            <div className="labelRow1">
              <label>
                Message<span>*</span>
              </label>
            </div>
            <textarea
              className="contactInput"
              id="text-box"
              type="text"
              name="message"
              placeholder="Message"
              rows="8"
              value={formState.message || ""}
              onChange={changeHandler}
              required="reqiureed"
            // onChange={(e) => {
            //   setFormState({ ...formState, message: e.target.value });
            // }}
            />

            <button onClick={submitHandler} className="contactInput">
              Send Email
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
