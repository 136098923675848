import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu } from "./Menu";
import "./Navbar.css";
import OkpoMyanmarLogo from "../assets/images/OkpoMyanmarLogo.png";

export class Navbar extends Component {
  state = { clicked: false };
  clickHandler = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  render() {
    return (
      <div>
        <nav className="navbar">
          <a href="/">
            <img src={OkpoMyanmarLogo} className="navbar-logo" alt="logo" />
          </a>
          {/* <h1 className="navbar-logo">
            <i class="fa-solid fa-clover"></i>Logo
          </h1> */}
          <div className="menu-icons" onClick={this.clickHandler}>
            <i class={this.state.clicked ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul
            className={
              this.state.clicked ? "navbar-menu active" : "navbar-menu"
            }
          >
            {Menu.map((item, index) => {
              return (
                <li key={item.index}>
                  <Link className={item.cName} to={item.url}>
                    <i class={item.icon} />
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Navbar;
