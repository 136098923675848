import React from "react";
import "./OurServices.css";
import icon1 from "../assets/icons/available-home.png";
import icon2 from "../assets/icons/passport.png";
import icon3 from "../assets/icons/visa.png";
import icon4 from "../assets/icons/worker-application.png";
import icon5 from "../assets/icons/development.png";
import icon6 from "../assets/icons/medical-checkup.png";
import icon7 from "../assets/icons/boarding-pass.png";
import icon8 from "../assets/icons/work-pass.png";
import icon9 from "../assets/icons/assisting.png";

const OurServices = () => {
  return (
    <>
      <div className="ourservicesContainer">
        <div className="ourservices">
          <h1>Our Services</h1>
          <p>
            Okpo Myanmar takes pride in being able to provide a one-stop service
            for any local worker who intends to work overseas. Our services
            include:
          </p>
          {/* <ul className="serviceList">
            <li>Accomodation during the training period</li>
            <li>Passport application process</li>
            <li>Visa application</li>
            <li>OWIC(Overseas Worker Identification Card) application</li>
            <li>
              Various training (welding & grinding, piping installation &
              testing, electrical cable termination & parameter test etc.)
            </li>
            <li>Medical check-up (as required)</li>
            <li>Transportation and ticketing</li>
            <li>
              Assisting in oversea work pass application (monitoring and assure
              qualification certificate)
            </li>
            <li>Money Transfer</li>
            <li>
              Any other assistance our workers require from us either in country
              or abroad
            </li>
          </ul> */}
          <div className="listBox1">
            <div className="list1">
              <p>Accomodation during the training period</p>
              <img alt="iconImg" src={icon1} />
            </div>
            <div className="list2">
              <p>Passport application process</p>
              <img alt="iconImg" src={icon2} />
            </div>
            <div className="list3">
              <p>Visa application</p>
              <img alt="iconImg" src={icon3} />
            </div>
          </div>
          <div className="listBox2">
            <div className="list4">
              <p>OWIC(Overseas Worker Identification Card) application</p>
              <img alt="iconImg" src={icon4} />
            </div>
            <div className="list5">
              <p>
                Various training (welding & grinding, piping installation &
                testing, electrical cable termination & parameter test etc.)
              </p>
              <img alt="iconImg" src={icon5} />
            </div>
            <div className="list6">
              <p>Medical check-up (as required)</p>
              <img alt="iconImg" src={icon6} />
            </div>
          </div>
          <div className="listBox3">
            <div className="list7">
              <p>Transportation and ticketing</p>
              <img alt="iconImg" src={icon7} />
            </div>
            <div className="list8">
              <p>
                Assisting in oversea work pass application (monitoring and
                assure qualification certificate)
              </p>
              <img alt="iconImg" src={icon8} />
            </div>
            <div className="list9">
              <p>
                Any other assistance our workers require from us either in
                country or abroad
              </p>
              <img alt="iconImg" src={icon9} />
            </div>
          </div>
        </div>
        <div className="whychooseus">
          <h1>Why Choose Us</h1>
          <p>
            Okpo Myanmar strongly believes that the human workforce is the most
            vital resource of a country for its economic growth. That’s why we
            are dedicated to training local workers that meet international
            standards and take it upon as our mission.
          </p>
          <br />
          <p>
            Qualified instructors and effective training methods are the core
            components of Okpo Myanmar. We also possess one of the best training
            centers in the country. It is fully equipped, and has formerly
            trained and produced many capable workers for other employment
            agencies.
          </p>
          <br />
          <p>
            Our recruitment team is dedicated to thoroughly selecting candidates
            who exhibit exceptional talent and positive attitude. We provide
            them with comprehensive training on international standards and
            guidelines to ensure that they possess the requisite skills and
            knowledge to perform effectively in the workplace.
          </p>
          <br />
          <p>
            Okpo Myanmar is certified by the Myanma Shipyards’ safety standards
            and is officially registered and licensed by the government. We can
            provide qualified and safety awareness personnel and take full
            responsibility for all of our services.
          </p>
        </div>
      </div>
    </>
  );
};  

export default OurServices;
