import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import OurBackgroundsBody from "../components/OurBackgroundsBody";

function Ourbackgrounds() {
  return (
    <div>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={require("../../src/assets/images/OurBackground/header_back.jpg")}
        cText="hero-text-back"
        text="Our Backgrounds"
      />
      <OurBackgroundsBody />
      <Footer />
    </div>
  );
}

export default Ourbackgrounds;
