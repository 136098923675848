import React from "react";
import Footer from "../components/Footer";
import Homebody from "../components/Homebody";
import ImageSlider from "../components/ImageSlider";
import Navbar from "../components/Navbar";
import { SliderData } from "../components/SliderData";

function Home() {
  return (
    <>
      <Navbar />
      <ImageSlider slides={SliderData} />
      {/* <Hero
        cName="heroHome"
        heroImg={require("../../src/assets/images/bagan.jpg")}
        cText="heroHome-text"
        text="Transforming human resources into a capable human workforce"
      /> */}
      <Homebody />
      <Footer />
    </>
  );
}

export default Home;
