import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutUsbody from "../components/AboutUsbody";
import Footer from "../components/Footer";

function AboutUs() {
  return (
    <div>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={require("../assets/images/AboutUs/meeting.jpg")}
        cText="hero-text"
        text="About Us"
      />
      <AboutUsbody />
      <Footer />
    </div>
  );
}

export default AboutUs;
