import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

function Contact() {
  return (
    <div>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={require("../../src/assets/images/Contact/contact_big.jpg")}
        cText="hero-text"
        text="Contacts"
      />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Contact;
