import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footerGradient">
        <p className="footerText">
          Developed by<span>Technovation</span>
        </p>
        <p className="footerCopy">
          &copy; Copyright by<span>Okpomyanmar</span>
        </p>
      </div>
    </>
  );
}

export default Footer;
